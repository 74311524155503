import React, { useRef } from "react";
import Carousel from "nuka-carousel";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";

import { Link } from "gatsby"

import { FaPlay, FaArrowRight, FaFacebookSquare, FaInstagram, FaArrowLeft } from "react-icons/fa";
import Moment from "react-moment";
import moment from "moment";

const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 992 })
  return isDesktop ? children : null
}
const Tablet = ({ children }) => {
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 })
  return isTablet ? children : null
}
const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 })
  return isMobile ? children : null
}
const Default = ({ children }) => {
  const isNotMobile = useMediaQuery({ minWidth: 768 })
  return isNotMobile ? children : null
}

const CompanyEvents = [
  
  {
    title: "3D Gens Technology Roadshow Kuala Lumpur",
    date: "15 July 2024",
    show: true,
  },
  {
    title: "MTDC Road 2 Growth Sabah",
    date: "06 August 2024",
    venue: "Sabah Convention Centre, Sabah",
    show: true,
  },
  {
    title: "Shining 3D Concentrate Keep Shining 2024 APAC Reseller Meeting",
    date: "08 August 2024",
    endDate: "10 August 2024",
    venue: "Hangzhou, China",
    show: true,
  },
  {
    title: "Principles of Orthognathic Surgery",
    date: "09 August 2024",
    endDate: "11 August 2024",
    venue: "Pusat Kesehatan Ibu dan Anak Nasional RSAB Harapan Kita, Jakarta, Indonesia",
    show: true,
  },
  {
    title: "E-Plus-3D Global Partners Summit 2024",
    date: "10 October 2024",
    endDate: "11 October 2024",
    venue: "EPLUS3D HQ, Hangzhou, Zhejiang, China",
    show: true,
  },
]

const ButtonCarousel = styled.button`
  padding: 4px 24px;
  margin: 0;
  /* background-color: rgba(0,0,0,0.5); */
  background-color: transparent;
  color: black;
  border: none;
  font-size: 32px;

  :hover{
    /* background-color: rgba(50,50,50,0.5) */
    color: #17a99e;
  }
`
const CarouselBox = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 5px 0;
`

const EventItem = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 10px;
`

const ContentBox = styled.div`
  // display: flex;
  flex-direction: column;
  flex: 1;
  // minHeight: 40vh;
  height: 380px;
  width: 100%;
  padding: 25px;
  border-radius: 25px;
  background-color: #39dccf;
  transition: all .5s;
  p{
    margin: 0;
  }
`

const DateBox = styled.div`
  flex: 1 1 50%;
  margin-bottom: 10px;
  line-height: 200%;
  text-align: center;
  .dayDate{
    font-size: 50px;
    font-family: 'Roboto', sans-serif;
  }
  .monthDate{
    font-size: 30px;
    font-weight: bold;
    text-transform: uppercase;
  }
  hr{
    margin-top: 10px;
    background-color: #dc3998;
    opacity: 50%;
  }
`

const TextBox = styled.div`
  flex: 1 1 50%;
  display: flex;
  flex-direction: column;
  // justify-content: flex-end;
  // align-items: flex-end;
  // font-weight: light;
  .eventTitle{
    font-size: 20px;
    font-weight: lighter;
    margin-bottom: 5px;
    line-height: 100%;
  }
  .eventDetail{
    color: #dc3998;
    font-size: 14px;
    // font-weight: bold;
  }
`

const MobileCarouselButtons = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 10px;
  background-color: #1EAA9F; 
  // background-color: transparent;
  border-radius: 15px;
  button{
    flex: 1;
  }
`
const MobileButtonCarousel = styled.button`
  padding: 4px 24px;
  margin: 0;
  background-color: #1EAA9F; 
  // background-color: #ccc;
  border-radius: 15px;
  color: black;
  border: none;
  font-size: 32px;

  :hover{
    background-color: rgba(50,50,50,0.5);
    color: #17a99e;
  }
`


const EventCarousel = ({}) => {

  const carouselRef = useRef();

  const nextCarousel = event => {
    event.preventDefault();
    carouselRef.current.nextSlide();
  }
  const prevCarousel = event => {
    event.preventDefault();
    carouselRef.current.previousSlide();
  }

  const liveEvent = CompanyEvents.filter(eventitem => eventitem.show !== false);
  const totalEvent = liveEvent.length;

    return (
      <div>
        <Default>
          <CarouselBox>
            <ButtonCarousel onClick={prevCarousel}><FaArrowLeft/></ButtonCarousel>
            { 
            totalEvent != 1 ? 
            <Carousel 
            ref={carouselRef}
            style={{flex: 1}}
            slidesToShow={3}
            slidesToScroll={1}
            width="100%" 
            height="100%" 
            heightMode="max"
            cellSpacing={10}
            dragging={false} 
            pauseOnHover={true}
            withoutControls={true} 
            autoplay={false} 
            wrapAround={true} 
            swiping={true}>
              {CompanyEvents.filter(eventitem => eventitem.show !== false).sort(function(a, b){ return (moment(a.date).format('X') - moment().format('X'))-(moment(b.date).format('X') - moment().format('X'))}).map((eventitem, index) => (
              // {CompanyEvents.map((eventitem, index) => ( 
                <EventItem>
                  <ContentBox>
                    <DateBox>
                      {/* <p className="dayDate">
                        <Moment format="DD MMMM" date={eventitem.date} />
                      </p> */}
                      {/* { totalEvent != 1 ? 'cellAlign="center"' : '' } */}
                      <p className="dayDate">
                        <Moment format="DD" date={eventitem.date} />
                        {/* { eventitem.endDate ? (<> - <Moment format="DD" date={eventitem.endDate} /></>) : ("") } */}
                      </p>
                      <p className="monthDate">
                        <Moment format="MMM" date={eventitem.date} />
                      </p>
                      <hr></hr>
                    </DateBox>
                    <TextBox>
                      <p className="eventTitle">{eventitem.title}</p>
                      <p className="eventDetail"><Moment format="DD" date={eventitem.date} />{ eventitem.endDate ? (<> - <Moment format="DD" date={eventitem.endDate} /></>) : ("") }<Moment format=" MMMM YYYY" date={eventitem.date} /></p>
                      <p className="eventDetail">{eventitem.time}</p>
                      {eventitem.venue ? <p className="eventDetail">@ {eventitem.venue}</p> : ""}
                      {/* <p className="eventDetail">@ {eventitem.venue}</p> */}
                    </TextBox>
                  </ContentBox>
                </EventItem>
              ))}
            </Carousel> 
            : 
            <Carousel 
            ref={carouselRef}
            style={{flex: 1}}
            slidesToShow={3}
            slidesToScroll={1}
            width="100%" 
            height="100%" 
            heightMode="max"
            cellSpacing={10}
            cellAlign="center"
            dragging={false} 
            pauseOnHover={true}
            withoutControls={true} 
            autoplay={false} 
            wrapAround={false} 
            swiping={false}>
              {CompanyEvents.filter(eventitem => eventitem.show !== false).sort(function(a, b){ return (moment(a.date).format('X') - moment().format('X'))-(moment(b.date).format('X') - moment().format('X'))}).map((eventitem, index) => (
              // {CompanyEvents.map((eventitem, index) => ( 
                <EventItem>
                  <ContentBox>
                    <DateBox>
                      {/* <p className="dayDate">
                        <Moment format="DD MMMM" date={eventitem.date} />
                      </p> */}
                      { totalEvent != 1 ? 'cellAlign="center"' : '' }
                      <p className="dayDate">
                        <Moment format="DD" date={eventitem.date} />
                        {/* { eventitem.endDate ? (<> - <Moment format="DD" date={eventitem.endDate} /></>) : ("") } */}
                      </p>
                      <p className="monthDate">
                        <Moment format="MMM" date={eventitem.date} />
                      </p>
                      <hr></hr>
                    </DateBox>
                    <TextBox>
                      <p className="eventTitle">{eventitem.title}</p>
                      <p className="eventDetail"><Moment format="DD" date={eventitem.date} />{ eventitem.endDate ? (<> - <Moment format="DD" date={eventitem.endDate} /></>) : ("") }<Moment format=" MMMM YYYY" date={eventitem.date} /></p>
                      <p className="eventDetail">{eventitem.time}</p>
                      {eventitem.venue ? <p className="eventDetail">@ {eventitem.venue}</p> : ""}
                      {/* <p className="eventDetail">@ {eventitem.venue}</p> */}
                    </TextBox>
                  </ContentBox>
                </EventItem>
              ))}
            </Carousel>
            }
            
            <ButtonCarousel onClick={nextCarousel}><FaArrowRight/></ButtonCarousel>
          </CarouselBox>
        </Default>


        <Mobile>
          <CarouselBox>
            <Carousel 
            ref={carouselRef}
            style={{flex: 1}}
            slidesToShow={1}
            slidesToScroll={1}
            width="100%" 
            height="100%" 
            heightMode="max"
            dragging={true} 
            pauseOnHover={true}
            withoutControls={true} 
            autoplay={true} 
            wrapAround={true} 
            swiping={true}>
              {CompanyEvents.filter(eventitem => eventitem.show !== false).sort(function(a, b){ return (moment(a.date).format('X') - moment().format('X'))-(moment(b.date).format('X') - moment().format('X'))}).map((eventitem, index) => (
              // {CompanyEvents.map((eventitem, index) => ( 
                <EventItem>
                  <ContentBox>
                    <DateBox>
                      <p className="dayDate">
                        <Moment format="DD" date={eventitem.date} />
                      </p>
                      <p className="monthDate">
                        <Moment format="MMMM" date={eventitem.date} />
                      </p>
                    </DateBox>
                    <TextBox>
                      <p className="eventTitle">{eventitem.title}</p>
                      <p className="eventDetail"><Moment format="DD" date={eventitem.date} />{ eventitem.endDate ? (<> - <Moment format="DD" date={eventitem.endDate} /></>) : ("") }<Moment format=" MMMM YYYY" date={eventitem.date} /></p>
                      <p className="eventDetail">{eventitem.time}</p>
                      {eventitem.venue ? <p className="eventDetail">@ {eventitem.venue}</p> : ""}
                      {/* <p className="eventDetail">@ {eventitem.venue}</p> */}
                    </TextBox>
                  </ContentBox>
                </EventItem>
              ))}
            </Carousel>
          </CarouselBox>
          <MobileCarouselButtons>
            <MobileButtonCarousel onClick={prevCarousel}><FaArrowLeft/></MobileButtonCarousel>
            <MobileButtonCarousel onClick={nextCarousel}><FaArrowRight/></MobileButtonCarousel>
          </MobileCarouselButtons>
        </Mobile>
      </div>
    )
}

export default EventCarousel